/*
 * @Date: 2022-06-16 14:52:11
 * @LastEditors: chenyufan
 * @LastEditTime: 2023-12-19 18:02:55
 * @FilePath: /front-public/packages/work-platform/src/api/interceptors.ts
 */
import API from '@cloudpivot-shared/domain-api';
import { getLogoutPageConfig } from '@WorkPlatform/common/logoutConfig';
import { removeStorage, setCookie } from '@WorkPlatform/common/utils';

API.Global.addInterceptor(
  'responseError',
  async err => {
    const getErrorStatus = (error: any) =>
      error.httpStatus || (error.response && error.response.status);
    const status: any = getErrorStatus(err);

    if (status === 401) {
      let address: string = await getLogoutPageConfig();
      removeStorage();
      let redirectUrl: string = window.location.href;
      redirectUrl = redirectUrl.replace('#', 'hashsymbol');
      if (address) {
        const key = 'redirect_url';
        const reg = /\$\{redirect_url\}/;
        if (reg.test(address)) {
          address = address.replace(reg, `${key}=${encodeURIComponent(redirectUrl)}`);
        } else {
          if (address.includes('?')) {
            address += `&${key}=${encodeURIComponent(redirectUrl)}`;
          } else {
            address += `?${key}=${encodeURIComponent(redirectUrl)}`;
          }
        }
        localStorage.removeItem('logoutPage');
        window.location.href = address;
      } else {
        const url: string = '/platform/#/login';
        const href: string = window.location.href;

        const isLogin: boolean = href.includes('/login');
        const wait: number = 1000;

        if (!isLogin) {
          window.location.href = `${url}?redirect_url=${encodeURIComponent(redirectUrl)}`;
          setTimeout(() => {
            location.reload();
          }, wait);
        }
      }

      return true;
    } else if (status === 403) {
      return true;
    }
    return false;
  },
  true,
);

// API的请求接口拦截器
API.Global.addInterceptor(
  'request',
  config => {
    // 忽略添加token的地址
    const ignoredTokenURLs = [
      '/api/sm4/security/get/switch/config',
    ];
    const hasIgnored = ignoredTokenURLs.some(i => {
      return config.url.indexOf(i) !== -1;
    });
    if (hasIgnored && config.url.indexOf('logout') < 0) {
      if (config.headers.Authorization) {
        delete config.headers.Authorization;
      }
      return true;
    }

    const appEntrance = sessionStorage.getItem('appEntrance') || 'platform';
    if (appEntrance) {
      config.headers['app-entrance'] = appEntrance;
      setCookie('app-entrance', appEntrance, { path: '/' });
    }

    // 其他地址查找localStorag 的token,自动附带到请求头上
    let token = localStorage.getItem('token');

    if (!token && config.headers.ruleToken) {
      token = config.headers.ruleToken;

      delete config.headers.ruleToken;
    }
    if (
      token &&
      !config.url.includes('/api/authorization/login/auth/get_rsa_key') &&
      !config.url.includes('/api/authorization/login/password/user') &&
      !config.url.includes('/api/authorization/login/auth/refresh_token/switch')
    ) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    const tenantCode = sessionStorage.getItem('tenantCode');
    let tenants: any[] = [];
    const tenantData = localStorage.getItem('tenants');
    if (tenantData && tenantData !== 'undefined') {
      tenants = JSON.parse(tenantData);
    }
    const slice = tenants.find(item => item.tenantCode === tenantCode)?.slice || 0;
    if (
      tenantCode &&
      !config.url.includes('/api/authorization/login/auth/get_rsa_key') &&
      !config.url.includes('/api/authorization/login/password/user') &&
      !config.url.includes('/api/authorization/login/auth/refresh_token/switch') &&
      !config.url.includes('/api/webapi/tenant/sys/security/password_regular_expression')
    ) {
      config.headers.tenantCode = tenantCode;
      config.headers.slice = slice;
    }
    return true;
  },
  false,
);
